import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Nav, Navbar, Container } from "react-bootstrap"

import logo from "../images/nautilus-designs-logo-white.svg"

const Header = ({siteTitle}) => (
  <header className="blueBG">
    <Container>
      <Navbar expand="md" className="navbar-dark">
        <Link to="/"><img src={logo} alt={siteTitle} width="200" className="logo py-1 py-sm-3" /></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">

          <Nav className="navbar-nav text-center ms-auto">
            <ul className="navbar-nav  text-center">
              <li><Link to="/about/" partiallyActive={true} className="nav-link" activeClassName="active">About</Link></li>

              <li><Link to="/podcasts/" partiallyActive={true} className="nav-link" activeClassName="active">Podcasts</Link></li>

              <li><Link to="/web-design-development/" partiallyActive={true} className="nav-link" activeClassName="active">Web Design & Development</Link></li>
              
              <li><Link to="/contact/" className="nav-link" activeClassName="active">Contact</Link></li>
              
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
